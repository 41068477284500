import { useApiUrl, useCustom } from '@refinedev/core';
import { Button, Form, Modal, Select, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export type CreateSubscriptionModalProps = {
  isOpen: boolean;
  teamId: string;
  onCancel: () => void;
}

const CreateSubscriptionModal: React.FC<CreateSubscriptionModalProps> = ({
  isOpen,
  teamId,
  onCancel,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const apiUrl = useApiUrl();

  const [form] = Form.useForm();

  const { data, refetch } = useCustom({
    url: `${apiUrl}/billbill/getPlans`,
    method: "get",
  })
  const plans = useMemo(() => {
    const d = data?.data || [];
    return d.map(p => {
      console.log(p);
      return ({ label: p.plan.planName, value: p.plan.id })
    })
  }, [data])

  const { refetch: subscribe, isFetching } = useCustom({
    url: `${apiUrl}/billbill/initSubscription`,
    method: 'get',
    config: {
      query: {
        teamId,
        planId: selectedPlan,
      }
    },
    queryOptions: {
      retry: false,
      refetchOnMount: false,
      enabled: false,
    },
    successNotification: () => {
      return {
        message: `Successfully initialized.`,
        type: "success",
      };
    },
    errorNotification: () => {
      return {
        message: 'Init error!',
        type: "error",
      }
    }
  })

  useEffect(() => {
    refetch();
  }, [refetch, isOpen]);

  const onReset = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [form, onCancel]);

  const onOk = useCallback(() => {
    subscribe().finally(() => {
      onReset();
      onCancel()
    })
  }, [onCancel, onReset, subscribe])

  return (
    <Modal
      title={"Подписка BillBill"}
      open={isOpen}
      onCancel={() => onReset()}
      destroyOnClose
      footer={[
        <Form.Item>
          <Space>
            <Button
              type="primary"
              onClick={onOk}
              disabled={!selectedPlan}
              loading={isFetching}
            >
              Подключить
            </Button>
            <Button onClick={onReset}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      ]}
    >
      <div>
        <Typography.Paragraph>
          Будет создан новый аккаунт, подписка и отправлена первая метрика
        </Typography.Paragraph>
        Выберете план для подключения
        <br />
        <Select
          style={{ width: '100%' }}
          options={plans}
          onChange={val => setSelectedPlan(val)}
          value={selectedPlan}
        />
      </div>
    </Modal>
  );
}

export default CreateSubscriptionModal