import {
  useShow,
  IResourceComponentsProps,
  useDelete,
  useApiUrl, useCustomMutation, useCustom,
} from '@refinedev/core';
import { Show } from "@refinedev/antd";
import { Button, Col, Popconfirm, Row, Tabs, Typography } from "antd";
import {
  DeleteOutlined,
  ReloadOutlined,
  LinkOutlined,
  DownloadOutlined, CalculatorOutlined,
} from '@ant-design/icons';
import * as dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import BillingMetricsTotalList from '../../../components/BillingMetricsTotalList';
import EventList from "../../../components/EventList";
import { ITeamModel } from "../models/TeamModel";
import CreateSubscriptionModal from './components/CreateSubscriptionModal';
import DumpModal, { DumpValues } from "./components/DumpModal";
import BillingMetrics from "./show/BillingMetrics";
import EditTeam from './show/EditTeam';
import { TeamShowMain } from "./show/TeamShowMain";

export const TeamShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ITeamModel>({
    resource: "teams",
  });
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data as ITeamModel;
  const apiUrl = useApiUrl();
  const [showDumpModal, setShowDumpModal] = useState(false);
  const [showBillBillModal, setBillBillModal] = useState(false);
  const { mutate: removeTeam } = useDelete();
  const {mutate: customCall, isLoading: isCustomLoading} = useCustomMutation<ITeamModel>();

  const [activeTab, setActiveTab] = useState("main");

  const initMetrics = () => {
    customCall({
      url: `${apiUrl}/billing_metrics_total/initializeBillingMetricsForTeam/${record.id}`,
      method: "post",
      values: {},
    }, {
      onSuccess: () => refetch(),
    })
  }

  const onRemove = () => {
    removeTeam(
      {
        resource: "teams",
        id: record.id,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const onRecover = () => {
    // @ts-ignore
    recoverTeam(
      {
        url: `${apiUrl}/teams/${record.id}/recover`,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const onDump = (data: DumpValues) => {
    // @ts-ignore
    customCall(
      {
        url: `${apiUrl}/teams/${record.id}/dump`,
        method: "post",
        values: {
          newTeamId: data.newTeamId,
          newUserId: data.newUserId,
          newBucketUrl: data.newBucketUrl,
          withUsers: data.withUsers,
          adminEmail: data.adminEmail,
        },
      },
      {
        onSuccess: (res) => {
          const a = document.createElement("a");
          a.href =
            "data:application/octet-stream," + encodeURIComponent(res.data);
          a.download = `${record.subdomain}-${Date.now()}.sql`;
          a.click();
        },
      }
    );

    setShowDumpModal(false);
  };

  const { refetch: sentInitMetrics, isFetching: isInitMetricLoading } = useCustom({
    url: `${apiUrl}/billbill/initSubscription`,
    method: 'get',
    config: {
      query: {
        teamId: record?.id,
      }
    },
    queryOptions: {
      retry: false,
      refetchOnMount: false,
      enabled: false,
    },
    successNotification: () => {
      return {
        message: `Successfully send.`,
        type: "success",
      };
    },
    errorNotification: () => {
      return {
        message: 'Init error!',
        type: "error",
      }
    }
  })

  const onTabChange = useCallback((key: string) => {
    window.location.hash = key;
  }, []);

  useEffect(() => {
    const key = window.location.hash.replace("#", "");
    if (key) {
      setActiveTab(key);
    }
  }, []);

  if (!record) {
    return <Show isLoading={isLoading} />;
  }

  return (
    <Show isLoading={isLoading}>
      <DumpModal
        onOk={onDump}
        onCancel={() => setShowDumpModal(false)}
        open={showDumpModal}
      />
      <CreateSubscriptionModal
        isOpen={showBillBillModal}
        teamId={record.id}
        onCancel={() => setBillBillModal(false)}
      />
      <Row justify="space-between">
        <Col span={12}>
          <Typography.Title level={2}>
            {record?.name} / {record.subdomain}
            <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
              .yonote.ru
            </span>{" "}
            <small>
              <a href={"https://" + record.subdomain + ".yonote.ru"}>
                <LinkOutlined />
              </a>
            </small>
          </Typography.Title>
          {record.deletedAt ? (
            <Typography.Title level={3} type="danger">
              Удалена: {dayjs(record.deletedAt).format("DD.MM.YYYY HH:mm:ss")} (
              {dayjs(record.deletedAt).fromNow()})
            </Typography.Title>
          ) : null}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Row justify="end" gutter={10}>
            <Col>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => setShowDumpModal(true)}
                loading={isCustomLoading}
              >
                Снять дамп
              </Button>
            </Col>
            <Col>
              <Button type="dashed" icon={<CalculatorOutlined />} onClick={() => setBillBillModal(true)}>
                Инициировать подписку BillBill
              </Button>
              <Button
                type="dashed"
                icon={<CalculatorOutlined />}
                onClick={sentInitMetrics}
                loading={isInitMetricLoading}
              >
                Отправить первую метрику
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title="Пересчитать метрики?"
                description="Записи метрик будут пересчитанны и записанны?"
                onConfirm={initMetrics}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="dashed" icon={<CalculatorOutlined />} loading={isCustomLoading}>
                  Пересчитать метрики
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              {!record.deletedAt ? (
                <Popconfirm
                  title="Delete the team?"
                  description="Are you sure to delete this team?"
                  onConfirm={onRemove}
                  okText="Yes! DELETE IT!"
                  cancelText="No"
                >
                  <Button type="dashed" danger icon={<DeleteOutlined />}>
                    Remove this team
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  onClick={() => onRecover()}
                >
                  Восстановить
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={onTabChange}
        items={[
          {
            key: "main",
            label: "Основное",
            children: <TeamShowMain team={record} />,
          },
          {
            key: "metrics",
            label: "Биллинг",
            children: <BillingMetrics team={record} />,
          },
          {
            key: "metrics_total",
            label: "Метрики",
            children: <BillingMetricsTotalList teamId={record.id} />
          },
          {
            key: "events",
            label: "События",
            children: <EventList teamId={record.id} />,
          },
          {
            key: "edit",
            label: "Редактировать",
            children: <EditTeam team={record} />,
          },
        ]}
      />
    </Show>
  );
};
